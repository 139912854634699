import {Box, Flex, VStack} from '@indoqa/style-system'
import {authenticator, useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {DigitalHeaderPanel} from '../components/header/DigitalHeaderPanel'
import {LoginProviderPanel, Provider} from '../components/login-provider/LoginProviderPanel'
import {digitalKvsLoginPage, digitalLoginPage, digitalPathOverview, digitalQueryParams} from '../store/digital.paths'
import {getKvRedirectUri} from './DigitalKVSManzRedirectPage'

const getPathAndParameters = (url: string): string => {
  const urlObject = new URL(url)
  return urlObject.pathname + urlObject.search
}

export const DigitalKvsLoginPage = () => {
  const history = useHistory()
  const redirectUri = useQueryParam(digitalQueryParams.redirect)
  // const testSystem = getInitialState().it

  // go to homepage if the user is already logged in
  if (authenticator.isAuthenticated()) {
    if (redirectUri && redirectUri.length > 0) {
      history.push(getPathAndParameters(redirectUri))
    } else {
      history.push(digitalPathOverview())
    }
  }

  const authenticateManz = React.useCallback(() => {
    history.push(digitalKvsLoginPage(redirectUri))
    authenticator.login('MANZ', getKvRedirectUri())
  }, [history, redirectUri])

  const authenticateJustiz = () => {
    history.push(digitalKvsLoginPage(redirectUri))
    authenticator.login('JUSTIZ', redirectUri)
  }

  const authenticateAMS = () => {
    history.push(digitalKvsLoginPage(redirectUri))
    authenticator.login('AMS', redirectUri)
  }

  const header = <DigitalHeaderPanel noSearch noLogin />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer} noFooterMarginTop>
      <DigitalHelmet canonical={digitalLoginPage()} />
      <Flex px={3} pt={16} justifyContent="center" fullWidth>
        <VStack spacing={16}>
          <LoginProviderPanel provider={Provider.MANZ} onClick={() => authenticateManz()} />
          <LoginProviderPanel provider={Provider.JUSTIZ} onClick={() => authenticateJustiz()} />
          <LoginProviderPanel provider={Provider.AMS} onClick={() => authenticateAMS()} />

          <Box pt={4} textAlign="center">
            <Link to={digitalLoginPage()}>Zurück zur Login-Seite</Link>
          </Box>
        </VStack>
      </Flex>
    </ContentLayout>
  )
}
