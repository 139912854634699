import {authenticator} from '@oegbv/ui-shared'
import * as React from 'react'
import {useEffect} from 'react'
import {kvPathOverview} from '../../kv/store/kv.paths'
import {digitalManzLoginRedirect} from '../store/digital.paths'

export const getKvRedirectUri = () => {
  const schema = window.location.protocol
  const domain = window.location.hostname
  const port = window.location.port
  return `${schema}//${domain}${port ? ':' + port : ''}` + kvPathOverview()
}

/**
 * Redirect URL for Manz (CP-1044)
 */
export const DigitalKVSManzRedirectPage = () => {
  useEffect(() => {
    if (authenticator.isAuthenticated()) {
      authenticator.logout(window.location.href + digitalManzLoginRedirect())
    } else {
      const redirectUri = getKvRedirectUri()
      authenticator.login('MANZ', redirectUri)
    }
  }, [])

  return <div />
}
